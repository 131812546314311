import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import MainSection from "../components/MainSection"
import ProjectsCard from "../components/Projects/ProjectsCard"

export const query = graphql`
  query {
    imagesJson(pageName: { eq: "projects" }) {
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      projects {
        name
        directory
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const ProjectsPage = ({ data }) => {
  const images = data.imagesJson

  return (
    <Layout>
      <SEO title="Projects" />
      <MainSection image={images.background.childImageSharp.fluid}>
        <p className="lead">Bob Keach &amp; Sons Carpentry</p>
        <h1>Projects</h1>
        <h2>Offering high-quality remodeling service since 1984.</h2>
      </MainSection>

      <section id="projects-page">
        <Container>
          <Row>
            {images.projects.map(project => (
              <ProjectsCard
                key={project.directory}
                directory={project.directory}
                name={project.name}
                image={project.image.childImageSharp.fluid}
                alt={project.name}
              />
            ))}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ProjectsPage
