import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

const ProjectCard = ({ directory, image, name }) => {
  if (!directory || !image || !name) return null

  return (
    <Col sm={6} lg={4}>
      <Card border="light" style={{ width: "23rem" }}>
        <Link to={`/projects/${directory}`}>
          <Img className="card-img-top" fluid={image} alt={name} />
        </Link>
        <Card.Body>
          <Card.Title>
            <Link to={`/projects/${directory}`}>{name}</Link>
          </Card.Title>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ProjectCard
